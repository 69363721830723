
    <!-- <div class="earthy-worthy-section earthy-worthy-menu"> -->
        <div class="container-fluid header sticky-header" *ngIf="configs.title != 'Aiema'">
            <header>
                <div class="inq-htop-st inq-row" id="banner" *ngIf="promotionBanner != '' && configs.title === 'Deera'">
                    <div class="text-center">
                        <p>
                            <span [innerHtml]="promotionBanner"></span>
                            <!-- <span>
                                <a href="#"><strong> More Details</strong></a>
                            </span> -->
                        </p>
                        <!-- <p>Get <strong>UPTO 40% OFF</strong> on your 1st order <span><a href="#"><strong> More Details</strong>
                                </a></span></p> -->
                        <span style="cursor: pointer;"><i class="fa fa-close" (click)='hideBanner()'></i></span>
                    </div>
                </div>
                <div class="flex-container" *ngIf="configs.title != 'BestGifts'">
                    <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer mob-logo" height="50" alt="Logo" [routerLink]="['/']">
                    <div class="header-row-one">
                        <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px;">
                            <form [formGroup]="searchForm" class="search-bar">
                                <div class="d-flex justify-content-between search-input">
                                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                            <div *ngIf="configs.title!=='BombayHardware'" class="dropdown-menu autocomplete-items">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>                                      
                                            </div>
                                            <div *ngIf="configs.title==='BombayHardware'" class="dropdown-menu autocomplete-items">
                                                <div>
                                                    <a class="dropdown-item" (click)="navigateToList1('GI')"  style="cursor:pointer;font-size: 14px !important;">GI</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('GP')"  style="cursor:pointer;font-size: 14px !important;">GP</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('MS')"  style="cursor:pointer;font-size: 14px !important;">MS</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('RHS&SHS')"  style="cursor:pointer;font-size: 14px !important;">RHS&SHS</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Seamless')"  style="cursor:pointer;font-size: 14px !important;">Seamless</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('SpiralWeilded')"  style="cursor:pointer;font-size: 14px !important;">SpiralWeilded</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('UPVC')"  style="cursor:pointer;font-size: 14px !important;">UPVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('CPVC')"  style="cursor:pointer;font-size: 14px !important;">CPVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('PVC')"  style="cursor:pointer;font-size: 14px !important;">PVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('SWR')"  style="cursor:pointer;font-size: 14px !important;">SWR</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Flats')"  style="cursor:pointer;font-size: 14px !important;">Flats</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Angles')"  style="cursor:pointer;font-size: 14px !important;">Angles</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Pumps')"  style="cursor:pointer;font-size: 14px !important;">Pumps</a>
                                                </div>                                      
                                            </div>
                                          </div>
                                          
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items1">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-btn">
                                            <button class="btn btn-solid" >
                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                            </button>
                                        </div> 
                                    </div>
                                </div>
                            </form>   
                            <ul class="account mobile-cart" style="margin-top:-40px;margin-bottom:0px !important;margin-left:10px">            
                                <li class="header-cart account-popup" style="margin-right:-17px">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title">
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>                         
                        </div>
                        <div class="account-section">
                            <ul class="account">
                                
                                <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:10px !important">
                                    <a href="javascript:;"  style="text-decoration: none !important;">
                                        <span class="fa fa-user" style="font-size:25px">
                                        </span> Login
                                    </a>
                                </li>
        
                                <li *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <a *ngIf= "configs.title !== 'BombayHardware'" class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                            <a *ngIf= "configs.title === 'BombayHardware'" class="logout-cutom dropdown-item" routerLink="enquiry-status">My Enquiry</a>

                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div>
                                </li>
        
                                <li class="header-cart account-popup desktop-only">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title">
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                        </div>
        
                    </div>
        
                </div>
        
            </header>
        </div>
        <div class="container-fluid navbar-row" *ngIf="configs.title != 'Aiema' && configs.title != 'BestGifts'">
            <app-menu></app-menu>
        </div>
    <!-- </div> -->
    <div *ngIf="configs.title === 'Aiema'">
        <div class="earthy-worthy-section earthy-worthy-menu">
            <div class="container-fluid header sticky-header" >
                <header>
                    <div class="flex-container">
                        <img [src]="logoImage" alt="Logo Image" class="custom-pointer aiema-logo" height="50" alt="Logo" style="width: 72px!important; height: 63px!important;"
                                    [routerLink]="['/']">
                        <div class="header-row-one" style="width: 100%;">
                            <div class="search-bar-group" style="margin-right: 10px !important;display:contents;z-index:10; width:100%;margin-top: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style=" width:100%;margin-top:15px">
                                    <div class="d-flex justify-content-between search-input-aiema" style="margin-right:25px;">
                                        <!-- <p>Browse all <br><span>Categories</span></p> -->
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                                <div class="dropdown-menu autocomplete-items">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items1">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="account-section">
                                <ul class="account" style="margin-right:0px">
                                    <li *ngIf="!appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;font-size: 14px;font-weight: 500;"><span
                                                class="fa fa-user" style="font-size:25px; color: rgb(102, 102, 102);">
                                                </span>
                                                User/Member Login
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <a class="logout-cutom dropdown-item" routerLink="login-new">User Login/Register</a>
                                                <a class="logout-cutom dropdown-item" routerLink="member-register">Member Register</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li *ngIf="appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName }}
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                                <!-- <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a> -->
                                                <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                        </div>
            
                    </div>
            
                </header>
            </div>
            <div class="container-fluid navbar-row" style="padding-left: 0px !important;padding-right: 0px !important;">
                <app-menu></app-menu>
            </div>
        </div>
    </div>
<!-- </div> -->


<div class="container-fluid header sticky-header" *ngIf="configs.title === 'BestGifts'">
    <header>
        <div class="row" >
            <div class="col-4"></div>
            <div class="col-4" style="justify-content: center!important;display:flex !important;">
                <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer" height="80" width="120" alt="Logo"
                [routerLink]="['/']"> 
            </div>
            <div class="col-4" style="margin-top: 13px;">
                
                    <ul class="d-flex" style="margin-left: 39%;">
                        <li class="d-flex header-cart account-popup1" style="margin-right: 18%;">
                            <a href="javascript:;"  style="text-decoration: none !important;" (click)="search()">
                                <i class="fa fa-search" aria-hidden="true"></i> Search
                            </a>
                            <div class="min-cart-product-wrapper popup-content2">
                                <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                        <div *ngFor="let category of allCategories">
                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                        </div>                                      
                                                    </div>
                                                  </div>
                                                <input type="text" placeholder="Search here."
                                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                <div *ngIf="preList.length != 0">
                                                    <div class="autocomplete-items2">
                                                        <div *ngFor="let preItem of preList">
                                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-solid" >
                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                    
                                </div>
                                
                        
                            </div>
                        </li>
                        
                        <!-- <li>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <div class="input-group-append">
                              <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </li> -->
                        
                        <li class="d-flex" style="margin-right: 6%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                            <a href="javascript:;"  style="text-decoration: none !important;">
                                <span class="fa fa-user" style="font-size:25px">
                                </span> Login
                            </a>
                        </li>

                        <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                            <div class="dropdown">
                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                        class="lnr lnr-user">
                                    </span>
                                    {{ appService.userName }}
                                </a>
                                <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                    
                                    <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                </div>
                            </div>
                        </li>

                        <li class="header-cart account-popup d-flex">                                        
                            <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                <span class="fa fa-shopping-cart" style="font-size:25px">
                                    <span style="position: absolute;
                                    background: #FBCB0C;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 9px;
                                    top: -8px;
                                    right: 76px;" >
                                        <span style="  color: #fff;
                                        position: absolute;
                                        left: 0.2rem;
                                        font-size: 14px;
                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>  
                                 <!-- <span class="menu-title">
                                    Cart
                                </span> -->
                            </a>
                            <app-mini-cart></app-mini-cart>
                        </li>
                    </ul>
            
            </div>
        </div>
        <div  *ngIf="configs.title === 'BestGifts'"> 
                <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                        class="navbar-toggler"><span class="navbar-toggler-icon"></span>
                    </button>
                    <div id="navbarNav" class="collapse navbar-collapse bestgift-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                    href="#/">HOME</a></li>
                            <li class="header-cart account-popup1" id="shop">
                                <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                <app-new-navbar></app-new-navbar> 
                                </li>
                                <li class="nav-item" id="shop"><a class="nav-link menu-text" style="cursor: pointer;"
                                    (click)="productsList()">BEST GIFTS</a></li>
                                    <li class="nav-item" id="shop"><a class="nav-link menu-text" style="cursor: pointer;"
                                        (click)="productsList()">VALENTINE SPECIAL</a></li>
                            <li class="nav-item" id="about"><a class="nav-link menu-text" ng-reflect-router-link="about-us"
                                    href="#/about-us">ABOUT US</a></li>
                            <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                    href="#/contact-us">CONTACT US</a></li>
                                    
                        </ul>
                    </div>
                </nav>
            </div>

    </header>
</div>
    <!-- </div> -->
