import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../header/Header/header.service';
import metaData from 'src/assets/data/default/header-footer.json';
import { config }  from 'src/configs/config';

@Component({
  selector: 'app-two-footer',
  templateUrl: './footer-two.component.html'
})
export class FooterTwoComponent implements OnInit {
  config=config;

  copyRightText:any = "";

  onLineShopping = metaData.footer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    // private appService : AppService
  ) { }

  ngOnInit(): void {
    // this.getCopyRightText();
  }

  navigateToProduct(link){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    console.log("link",link)
    window.scrollTo({top: 0, behavior: 'smooth'});
    // this.router.navigate([`/productListing`], {queryParams: { terms:link, type:'filter' }, relativeTo:this.route,  skipLocationChange: false });
   if(config.title==='BombayHardware'){
    // this.router.navigate([`/category-listing`], {queryParams: {}, relativeTo:this.route,  skipLocationChange: false });
    // this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
    // this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
   } if(config.title === "BBold"){
    this.router.navigate([`/product`], {queryParams: { }, relativeTo:this.route,  skipLocationChange: false });
  } else {
    this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

   }

   
    // if(link==='/productListing') {
    //   this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

    // } 
    // if(link==='/category-listing'){
    //   this.router.navigate([`/category-listing`]);

    // }

  }

  scrollFunction(){
    window.scrollTo({top: 0, behavior: 'smooth'});

  }
  getBrochure(){
    //this.headerService.updateAdminFormEvent('clearSearchTerm');
    window.open("http://d1bo46l1lnh354.cloudfront.net/Mahaveer/Brochure/ITR-Lubricants-Brochure.pdf")
  }

  getLatestProducts(){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if(config.title==='BombayHardware'){
      // this.router.navigate([`/category-listing`], {queryParams: {}, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      console.log("check if this is called")
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
     } else {
      this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
     }
  }

  // getCopyRightText(){
  //   // REQUEST DATA
  //   let request_data = {
  //     "domain_name": "www.mahaveerdistributor.in",
  //     "user_id": 17,
  //     "extras": {
  //         "find": {}
  //     }
  //   }
  //   // MAKE AN API CALL
  //   this.appService.postApiCall(request_data, ENDPOINTS.STORE_SETTINGS).subscribe(
  //     resp => {
  //       let respData = resp.result.data[0];
  //       if(respData.website){
  //         this.copyRightText = respData.website.copyright_text;
  //       }
  //     },
  //     err => {
  //       if (err.error.error.message) {
  //         console.log(err.error.error.message)
  //       }
  //       else{
  //         console.log('Something bad happened; Please try again!')
  //       }
  //     })
  // }
  navigateToCategory(name){
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    this.router.navigate(['/']).then(()=>
    this.router.navigate([`/productListing`], { queryParams: { terms: [name], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false }));



  }
}
