<div *ngIf="CONFIG.title !== 'Nesma'">
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5">
                <div *ngFor="let category of allCategories; let i = index;">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                </div>
            </div>
            <div class="col-7">
                <!-- <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">&#x292C;</p> -->
                <!-- <img [src]="categoryImage1" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)"> -->
                <!-- <img [src]="categoryImage2" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
                <img src="assets/images/bestgifts/category1.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)">
                <img src="assets/images/bestgifts/category2.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)">
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>